export default {
    title: 'Climate Footprint - Food Ingredients',
    location: '/sustainability/climate-footprint-food-ingredients',
    tabs: [
        {
            name: 'By Country',
            link: `/sustainability/climate-footprint-food-ingredients`
        },
        {
            name: 'Trend',
            link: `/sustainability/climate-footprint-food-ingredients/:market`
        },
        // {
        //     name: '🔗 Operational Climate Footprint',
        //     link: `/sustainability/climate-footprint`
        // }
    ]
}
